import axios from 'axios';

const apiClient = axios.create({
    headers: {
        'X-CSRF-Token': typeof FLYWHEEL_CSRF_TOKEN !== 'undefined' ? FLYWHEEL_CSRF_TOKEN : '',
        'Accept': 'application/json'
    }
});

apiClient.interceptors.response.use((response) => {
    const redirect = response?.data?.redirect;
    if (redirect && redirect !== window.location.pathname) {
        window.location.pathname = redirect;
    }

    return response;
});

const { get, post, put } = apiClient;
const destroy = apiClient.delete;

export {
    destroy,
    get,
    post,
    put
};
