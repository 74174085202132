import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const Index = () => import('./pages/index/index');
const Billings = () => import('./pages/billings');
const Login = () => import('./pages/login');

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Index
        },
        {
            path: '/login',
            component: Login
        },
        {
            path: '/billings',
            component: Billings
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
            return {
                selector: to.hash
            };
        }

        return { x: 0, y: 0 };
    }
});

export default router;
