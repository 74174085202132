<template>
	<input type="submit" class="button" :disabled="working || disabled" :data-working="working">
</template>

<script>
export default {
	props: {
		working: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false }
	}
}
</script>
