import Button from 'vue-components/Button';
import VeeValidateErrorLabel from 'vue-components/VeeValidateErrorLabel';

export default {
	components: {
		Button,
		'vee-validate-error-label': VeeValidateErrorLabel
	},

	data () {
		return {
			password: '',
			confirmation: '',

			isWorking: false
		};
	},

	mounted () {
		this.password = this.$refs.password.value;
		this.confirmation = this.$refs.confirmation.value;
	},

	computed: {
		isValid () {
			return this.errors.count() === 0;
		}
	},

	methods: {
		validate () {
			this.$validator.validate().then((valid) => {
				if (valid) {
					this.isWorking = true;
					this.$refs.form.submit();
				}
			});
		}
	}
};
