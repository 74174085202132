import Button from 'vue-components/Button';

export default {
	components: {
		Button
	},

	data () {
		return {
			email: '',
			password: '',

			isWorking: false
		};
	},

	mounted () {
		this.password = this.$refs.password.value;
		this.email = this.$refs.email.value;
	},

	computed: {
		isValid () {
			return this.errors.count() === 0;
		}
	},

	methods: {
		validate () {
			this.$validator.validate().then(valid => {
				if (valid) {
					this.isWorking = true;
					this.$refs.form.submit();
				}
			});
		}
	}
};
