<template>
	<label v-if="error" v-cloak class="error">
		{{ error.msg }}
	</label>
</template>
<script>
export default {
	props: {
		fieldName: {
			type: String,
			required: true
		}
	},

	computed: {
		error() {
			return this.errors?.items.find((x) => x.field === this.fieldName);
		}
	}
};
</script>
