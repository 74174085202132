<template>
	<router-view></router-view>
</template>

<script>
import Vue from 'vue';
import router from './router';
import VueI18n from 'vue-i18n';
import VeeValidate from 'vee-validate';
import { fetchPortal } from './api/portal';

Vue.use(VueI18n);
Vue.use(VeeValidate);

const i18n = new VueI18n({
	locale: 'en'
});

export default {
	i18n,

	router,

	data() {
		return {
			customer: null
		};
	},

	created() {
		fetchPortal().then(({ data: { customer } }) => {
			this.customer = customer;
		});
	},

	provide() {
		return {
			getCustomer: () => this.customer
		};
	}
};
</script>
