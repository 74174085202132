/**
 * Client Portal > form validations
 *
 */
import Vue from 'vue';
import VeeValidate from 'vee-validate';

// Forms
import PasswordReplaceForm from './forms/password-replace-form';
import PasswordConfirmForm from './forms/password-confirm-form';
import SignInForm from './forms/signin-form';

export default {
	init() {
		Vue.use(VeeValidate);

		// Login
		if (document.getElementById('signin-form')) {
			new Vue({ ...SignInForm, el: '#signin-form' });
		}

		// Sign Up & Edit profile
		if (document.getElementById('password-replace-form')) {
			new Vue({ ...PasswordReplaceForm, el: '#password-replace-form' });
		}

		if (document.getElementById('password-confirm-form')) {
			new Vue({ ...PasswordConfirmForm, el: '#password-confirm-form' });
		}
	}
};
