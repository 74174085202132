/**
 * Main client portal file
 */

// Vendor files
import 'vendor/modernizr.min.js';
import 'vendor/confetti-source';
import 'vendor/cookies.min.js';
import 'vendor/svgxuse.min.js';
import 'jquery';
import 'jquery-ujs';
import 'jquery-validation';
import 'cookies-js';
import 'filesize';
import 'fontfaceobserver';
import 'platform';
import 'nodelist-foreach-polyfill';

// Helpers
import header from 'components/header';
import validation from 'helpers/validation';
import dropdowns from 'components/dropdowns';
import dropdownsShelf from 'components/dropdowns-shelf';
import tabNav from 'components/tab-nav';
import selectList from 'components/select-list';
import spinner from 'components/spinner';
import notifications from 'components/notification';
import tooltips from 'components/tooltips';

import Vue from 'vue';
import App from './client_portal/App';

import startup from 'startup.js';

import forms from 'client_portal/forms';

// Importing the `@rails/ujs` package and running `start()` ensures, among other things, that rails-ujs is able to
// properly handle `link_to` helpers utilizing the `method` option with a non-GET HTTP verb (`:delete`, `:post`, etc.)
//
// Example use case from our application which doesn't work without this import/start:
// `= link_to("Sign out", gs_logout_path, method: :delete)`
import Rails from '@rails/ujs';
Rails.start();

const initializeModules = () => {
	header.init();
	validation.init();
	dropdowns.init();
	dropdownsShelf.init();
	tabNav.init();
	selectList.init();
	spinner.init();
	notifications.init();
	tooltips.init();
	forms.init();
};

window.startup = startup();

const vueApp = document.querySelector('vue-app');
if (vueApp) {
	new Vue({ ...App, el: 'vue-app' });
}

export default (function () {
	if (!window.initialized) {
		initializeModules();
		window.initialized = true;
	}
}());
